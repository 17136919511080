import { addGroupOfUsersAsStudents, removeGroupOfUsersAsStudents } from 'API/course'
import { listGroups } from 'API/group'
import { getUserData } from 'API/user'
import { Link, navigate, PageProps } from 'gatsby'
import { useCourseHook } from 'hooks/useCourseHook'
import React, { useState } from 'react'
import { useEffect } from 'react'
import { Button, Form } from 'react-bootstrap'
import { toast } from 'react-toastify'
import { PromiseType } from 'utility-types'

const CourseAddGroup = (props: PageProps) => {
	const { CourseDisplay, CourseError, course } = useCourseHook(props.params.id)
	const [groups, setGroups] = useState<string[]>()
	const [query, setquery] = useState<string>()
	const [check, setCheck] = useState(false)
	const [remove, setRemove] = useState(false)
	const [userData, setUserData] = React.useState<PromiseType<ReturnType<typeof getUserData>>>()

	React.useEffect(() => {
		const run = async () => {
			const userData = await getUserData()
			if (userData) {
				setUserData(userData)
			}
		}
		run()
	}, [])
	useEffect(() => {
		const run = async () => {
			if (course) {
				const groups = await listGroups()
				if (groups) {
					setGroups(groups.map((group) => group.name))
				}
			}
		}
		// Parse url query

		if (props.location.search) {
			const remove = new URLSearchParams('?remove=true').get('remove')
			if (remove === 'true') {
				setRemove(true)
			}
		}
		run()
	}, [course])

	const handleClick = async (name: string) => {
		if (check) {
			toast('Already Processing', { type: 'error' })
		} else {
			setCheck(true)
			try {
				if (course) {
					if (remove) {
						await removeGroupOfUsersAsStudents(name, course.uid)
					} else {
						await addGroupOfUsersAsStudents(name, course.uid)
					}
					navigate(`/admin/course/${course.uid}`)
				} else {
					toast('Error')
				}
				setCheck(false)
			} catch (err) {
				setCheck(false)
			}
		}
	}

	return (
		<>
			{CourseDisplay}
			<p>{remove ? 'Remove' : 'Add'} Group as Students</p>

			<div style={{ margin: '0' }} className='col-6'>
				{/* <input type='text' placeholder='search' onChange={(e) => setquery(e.target.value)} /> */}
				<Form.Control type='search' onChange={(e) => setquery(e.target.value)} placeholder='Search' className='me-2' aria-label='Search' />
			</div>
			<table className='table'>
				<thead>
					<tr>
						<th scope='col'>#</th>
						<th scope='col'>Group Name</th>
						<th scope='col'></th>
					</tr>
				</thead>
				<tbody>
					{groups
						?.filter((ele) => ele.includes(query || ''))
						?.map((elem, index) => {
							return (
								<>
									<tr>
										<td scope='row'>{index + 1}</td>
										{userData?.admin && (
											<Link to={`./${elem}`}>
												<td>{elem}</td>
											</Link>
										)}
										<td>
											<Button onClick={() => handleClick(elem)} variant='primary'>
												{remove ? 'Remove' : 'Add'} Group To Course as Students
											</Button>
										</td>
									</tr>
								</>
							)
						})}
				</tbody>
			</table>
			{CourseError}
		</>
	)
}

export default CourseAddGroup
